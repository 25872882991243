'use client'

import Link from 'next/link'
import { useLocale } from 'next-intl'
import { ReactNode } from 'react'
import { locales } from '@/config/i18n'

interface LocaleLinkProps {
  href: string
  children: ReactNode
  className?: string
  legacyBehavior?: boolean; // Add legacyBehavior prop
  [key: string]: any
}

export function LocaleLink({ href, children, legacyBehavior, ...props }: LocaleLinkProps) {
  const locale = useLocale()
  
  // If it's an external link (has http/https) or hash link, don't modify
  if (href.startsWith('http') || href.startsWith('#')) {
    // Pass legacyBehavior to Link if provided
    return <Link href={href} legacyBehavior={legacyBehavior} {...props}>{children}</Link>
  }

  // Ensure the href starts with a slash
  const path = href.startsWith('/') ? href : `/${href}`
  
  // Only add locale if the path doesn't already have one
  const localizedHref = locales.some(l => path.startsWith(`/${l}/`) || path === `/${l}`)
    ? path
    : `/${locale}${path}`

  // Pass legacyBehavior to Link if provided
  return <Link href={localizedHref} legacyBehavior={legacyBehavior} {...props}>{children}</Link>
}
