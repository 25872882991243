'use client'

import { useTranslations } from 'next-intl'
// Using relative path instead of alias due to build resolution issues
import { LocaleLink } from '../../components/ui/LocaleLink'

export default function NotFound() {
  const t = useTranslations('common.NotFound')
  
  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="text-center">
        <h1 className="text-5xl font-bold text-gray-900 mb-4">404</h1>
        <p className="text-xl text-gray-600 mb-8">{t('pageNotFound')}</p>
        <LocaleLink 
          href="/" 
          className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition"
        >
          {t('backToHome')}
        </LocaleLink>
      </div>
    </div>
  )
}
